































import { Vue, Component, Prop } from 'vue-property-decorator';
import countryData from './CountryData';

@Component
export default class extends Vue {
  @Prop(String) readonly value!: string;
  countryData = countryData;

  get baseURL(): string {
    return process.env.BASE_URL as string;
  }
}
